export const EXPERIENCES = [
  {
    title: "Software Developer",
    company: "Electric Power Research Institute (EPRI)",
    location: "Palo Alto, CA",
    date: "Jun 2023 – Present",
    description: [
      ["Enhanced fuel analysis rate by developing damage detection desktop app using C#", []],
      ["Augmented fuel grid detection accuracy by training neural network using Python", []],
      [
        "Delivered 10 web and desktop apps while simultaneously juggling 3+ projects at all times",
        [],
      ],
    ],
    categories: ["Full-Stack", "Desktop", "Machine Learning"],
    languages: ["Python", "C#", "C++", "JavaScript", "TypeScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: [".NET", "Blazor", "WPF", "Angular", "Bootstrap", "Flask"],
    tools: ["Git", "Linux", "Docker", "SQL Server", "SQLite", "Azure"],
    machineLearning: [
      "PyTorch",
      "TensorFlow",
      "Keras",
      "scikit-learn",
      "Transformers",
      "NumPy",
      "pandas",
    ],
  },
  {
    title: "Associate Software Developer",
    company: "Electric Power Research Institute (EPRI)",
    location: "Palo Alto, CA",
    date: "Jul 2022 – Jun 2023",
    description: [
      ["Increased developer efficiency by implementing authorization system using C#", []],
      ["Raised auth config speed by designing admin control panel web app using C#", []],
      ["Worked full-time throughout master’s program", []],
    ],
    categories: ["Full-Stack"],
    languages: ["C#", "JavaScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: [".NET", "Blazor", "Bootstrap"],
    tools: ["Git", "Docker", "SQL Server", "Azure"],
    machineLearning: [],
  },
  {
    title: "Student Software Developer",
    company: "Electric Power Research Institute (EPRI)",
    location: "Palo Alto, CA",
    date: "Feb 2021 – Jul 2022",
    description: [
      ["Improved SQA testing reliability by building automated testing web app using C#", []],
      ["Sped up containment inspection by creating defect recognition web app using C#", []],
      ["Worked full-time during last semester of bachelor’s program", []],
    ],
    categories: ["Full-Stack"],
    languages: ["Python", "C#", "JavaScript", "TypeScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: [".NET", "Blazor", "Angular", "Bootstrap"],
    tools: ["Git", "Linux", "Docker", "SQL Server", "SQLite", "Azure"],
    machineLearning: ["NumPy", "pandas"],
  },
  {
    title: "Front-End Developer",
    company: "MediRev Solutions",
    location: "San José, CA",
    date: "Mar 2018 – Jul 2018",
    description: [["Designed company website using HTML and CSS", []]],
    categories: [],
    languages: ["HTML", "CSS"],
    frameworksLibraries: [],
    tools: [],
    machineLearning: [],
  },
  {
    title: "Developer Connection Intern",
    company: "Samsung Electronics America",
    location: "Mountain View, CA",
    date: "Jul 2017 – Oct 2017",
    description: [
      ["Learned C# and Unity to develop virtual reality arcade game", []],
      ["Fixed bugs in Samsung Unity VR workshop tutorial", []],
    ],
    categories: [],
    languages: ["C#"],
    frameworksLibraries: [],
    tools: [],
    machineLearning: [],
  },
];
